import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import api from "../api";
import Header from "../Header";
import { useAuth } from "../../context/AuthContext";
import "./Order.css"; // Import CSS for sticky header

const Order = () => {
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [status, setStatus] = useState("Pending");
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // Items per page
  const { userData } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("authToken");

    api
      .get("/dashboard/getOrders", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const fetchedOrders = response.data.orders;
        setOrders(fetchedOrders);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching orders:", error);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setFilteredOrders(orders.filter((order) => order.status === status));
  }, [status, orders]);

  // Pagination calculations
  const indexOfLastOrder = currentPage * itemsPerPage;
  const indexOfFirstOrder = indexOfLastOrder - itemsPerPage;
  const currentOrders = filteredOrders.slice(
    indexOfFirstOrder,
    indexOfLastOrder
  );

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const totalPages = Math.ceil(filteredOrders.length / itemsPerPage);

  const handleRowClick = (orderId, status) => {
    navigate(`/order-details/${orderId}`, { state: { status } });
  };

  return (
    <div className="main-content">
      <div className="container mt-4">
        <Header userName={userData.name} />
        <div className="d-flex justify-content-between mb-3">
          <button
            className={`btn ${
              status === "Pending" ? "btn-primary" : "btn-outline-primary"
            }`}
            onClick={() => setStatus("Pending")}
          >
            Pending
          </button>
          <button
            className={`btn ${
              status === "Assigned" ? "btn-primary" : "btn-outline-primary"
            }`}
            onClick={() => setStatus("Assigned")}
          >
            Assigned
          </button>
        </div>
        {loading ? (
          <div className="text-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : filteredOrders.length === 0 ? (
          <div className="text-center">
            <p>No Data Available</p>
          </div>
        ) : (
          <>
            <div className="table-container">
              <table className="table table-bordered sticky-header">
                <thead>
                  <tr>
                    <th>Order ID</th>
                    <th>Status</th>
                    <th>Date of Order</th>
                  </tr>
                </thead>
                <tbody>
                  {currentOrders.map((order) => (
                    <tr
                      key={order.orderId}
                      onClick={() =>
                        handleRowClick(order.orderId, order.status)
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <td>{order.orderId}</td>
                      <td>{order.status}</td>
                      <td>
                        {new Date(order.dateOfOrder).toLocaleDateString()}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <nav>
              <ul className="pagination">
                <li className="page-item">
                  <button
                    className="page-link"
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    Previous
                  </button>
                </li>
                {Array.from({ length: totalPages }, (_, index) => (
                  <li key={index} className="page-item">
                    <button
                      className="page-link"
                      onClick={() => handlePageChange(index + 1)}
                    >
                      {index + 1}
                    </button>
                  </li>
                ))}
                <li className="page-item">
                  <button
                    className="page-link"
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </button>
                </li>
              </ul>
            </nav>
          </>
        )}
      </div>
    </div>
  );
};

export default Order;
