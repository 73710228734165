import React, { useEffect, useState } from "react";
import {
  Table,
  Spinner,
  Alert,
  Card,
  Button,
  Modal,
  Form,
} from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import api from "../api";
import { Eye, EyeSlash } from "react-bootstrap-icons";
import BackButton from "../BackButton";
const StoreDetail = () => {
  const { storeId } = useParams(); // Extract storeId from the URL
  const [storeDetails, setStoreDetails] = useState(null);
  const [inventory, setInventory] = useState([]);
  const [loading, setLoading] = useState(true); // Combined loading state
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false); // State for modal visibility
  const [csvFile, setCsvFile] = useState(null); // State to store the CSV file
  const [uploading, setUploading] = useState(false); // State to track file upload
  const [uploadError, setUploadError] = useState(null); // State for upload error
  const navigate = useNavigate(); // Use navigate to change the route
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => setShowPassword(!showPassword);
  const token = localStorage.getItem("authToken");

  // Fetch store details and inventory concurrently
  const fetchData = async () => {
    try {
      const [storeResponse, inventoryResponse] = await Promise.all([
        api.get(`/store/get-storeDetails/${storeId}`, {
          headers: { Authorization: `Bearer ${token}` },
        }),
        api.get(`/store/assigned-inventories/${storeId}`, {
          headers: { Authorization: `Bearer ${token}` },
        }),
      ]);
      setStoreDetails(storeResponse.data.data);
      setInventory(inventoryResponse.data.assignedInventories);
      setLoading(false);
    } catch (err) {
      setError("Error fetching data. Please try again.");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [storeId]);

  // Handle row click to navigate to the inventory detail page
  const handleRowClick = (inventoryId) => {
    navigate(`/inventory-details/${inventoryId}`, { state: { storeDetails } }); // Pass the inventory ID to the details page
  };

  // Handle modal close
  const handleCloseModal = () => {
    setShowModal(false);
    setCsvFile(null); // Reset file input when modal closes
    setUploadError(null); // Reset upload error when modal closes
  };

  // Handle modal open
  const handleOpenModal = () => setShowModal(true);

  // Handle file change
  const handleFileChange = (e) => {
    setCsvFile(e.target.files[0]);
  };

  // Handle file upload
  const handleUpload = async () => {
    if (!csvFile) {
      alert("Please select a CSV file to upload.");
      return;
    }

    const formData = new FormData();
    formData.append("file", csvFile);

    try {
      setUploading(true);
      setUploadError(null); // Reset any previous errors
      const response = await api.post(
        `/store/assign-inventory/${storeId}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      alert("Inventory assigned successfully!");
      handleCloseModal();
      fetchData(); // Refresh the data after upload
    } catch (err) {
      setUploadError("Error uploading inventory. Please try again.");
    } finally {
      setUploading(false);
    }
  };

  return (
    <div className="main-content">
      <div className="container">
        {loading ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "100vh" }}
          >
            <Spinner animation="border" size="lg" />
          </div>
        ) : error ? (
          <Alert variant="danger">{error}</Alert>
        ) : (
          <>
            <BackButton></BackButton>
            <Card className="mb-4 shadow-sm">
              <Card.Body>
                <Card.Title className="text-primary mb-3">
                  {storeDetails.storeName}
                </Card.Title>
                <Card.Text>
                  <div className="mb-2">
                    <strong>ID:</strong> {storeDetails.storeOverview.storeId}
                  </div>
                  <div className="mb-2">
                    <strong>Total Billed Amount:</strong>{" "}
                    <span className="text-success">
                      {storeDetails.storeOverview.totalBilledAmount}
                    </span>
                  </div>
                  <div className="mb-2">
                    <strong>Active Bill Count:</strong>{" "}
                    <span className="badge bg-info text-dark">
                      {storeDetails.storeOverview.activeBillCount}
                    </span>
                  </div>
                  <div className="mb-2">
                    <strong>Deleted Bill Count:</strong>{" "}
                    <span className="badge bg-warning text-dark">
                      {storeDetails.storeOverview.deletedBillCount}
                    </span>
                  </div>
                  <div className="mb-2">
                    <strong>Address:</strong> {storeDetails.storeAddress},{" "}
                    {storeDetails.city}, {storeDetails.pincode},{" "}
                    {storeDetails.state}
                  </div>
                  <div className="mb-3">
                    <strong>Contact:</strong> {storeDetails.userName},{" "}
                    <span className="text-muted">{storeDetails.phoneNo}</span>,{" "}
                    <span className="text-muted">{storeDetails.emailID}</span>
                  </div>
                  <Form.Group controlId="password">
                    <Form.Label>
                      <strong>Password:</strong>
                    </Form.Label>
                    <div className="input-group">
                      <Form.Control
                        type={showPassword ? "text" : "password"}
                        value={storeDetails.password}
                        readOnly
                      />
                      <Button
                        variant="outline-secondary"
                        onClick={togglePasswordVisibility}
                      >
                        {showPassword ? <EyeSlash /> : <Eye />}
                      </Button>
                    </div>
                  </Form.Group>
                </Card.Text>
              </Card.Body>
            </Card>

            <Card className="mb-4 shadow-sm">
              <Card.Body>
                <Card.Title className="text-secondary mb-3">
                  Store Overview
                </Card.Title>
                <Card.Text>
                  <div className="mb-2">
                    <strong>Commission Percentage:</strong>{" "}
                    <span className="text-primary">
                      {storeDetails.storeOverview.commissionPercentage}%
                    </span>
                  </div>
                  <div>
                    <strong>Commission Earned:</strong>{" "}
                    <span className="text-success">
                      {storeDetails.storeOverview.commissionEarned}
                    </span>
                  </div>
                </Card.Text>
              </Card.Body>
            </Card>

            <h4 className="mt-4">Assigned Inventory</h4>
            <Button
              variant="primary"
              onClick={handleOpenModal}
              className="mt-3"
            >
              Assign Inventory
            </Button>

            {inventory.length === 0 ? (
              <Alert variant="info" className="mt-3">
                No inventory assigned to this store.
              </Alert>
            ) : (
              <Table striped bordered hover className="mt-3">
                <thead>
                  <tr>
                    <th>Inventory ID</th>
                    <th>Assigned Date</th>
                    <th>Received Date</th>
                    <th>Status</th>
                    <th>Total Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {inventory.map((item) => (
                    <tr
                      key={item.assignedInventoryId}
                      onClick={() => handleRowClick(item.assignedInventoryId)} // Click event to navigate
                      style={{ cursor: "pointer" }} // Indicate that row is clickable
                    >
                      <td>{item.assignedInventoryId}</td>
                      <td>
                        {new Date(item.assignedDate).toLocaleDateString()}
                      </td>
                      <td>
                        {item.receivedDate
                          ? new Date(item.receivedDate).toLocaleDateString()
                          : "Not received"}
                      </td>
                      <td>{item.status}</td>
                      <td>{item.totalAmountOfAssigned}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
          </>
        )}

        {/* Modal for Assign Inventory */}
        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Assign Inventory</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group>
                <Form.Label>Upload CSV File</Form.Label>
                <Form.Control
                  type="file"
                  accept=".csv"
                  onChange={handleFileChange}
                  disabled={uploading} // Disable input during upload
                />
              </Form.Group>
            </Form>
            {uploadError && (
              <Alert variant="danger" className="mt-3">
                {uploadError}
              </Alert>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={handleCloseModal}
              disabled={uploading}
            >
              Close
            </Button>
            <Button
              variant="primary"
              onClick={handleUpload}
              disabled={uploading}
            >
              {uploading ? (
                <>
                  <Spinner animation="border" size="sm" className="mr-2" />
                  Uploading...
                </>
              ) : (
                "Assign Inventory"
              )}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default StoreDetail;
