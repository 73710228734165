import api from "../api";
import { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useAuth } from "../../context/AuthContext";

const cardStyle = {
  height: "200px", // Set a fixed height for uniformity
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  textAlign: "center",
};

const OverView = () => {
  const [overviewData, setOverviewData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const { userData } = useAuth();

  useEffect(() => {
    const token = localStorage.getItem("authToken");

    // Fetch the data from the API with authorization token
    api
      .get("/dashboard/getOverview", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setOverviewData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setError("Error fetching data");
        setLoading(false);
      });

    // Update the current date and time every second
    const interval = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000);

    return () => clearInterval(interval); // Clear the interval on component unmount
  }, []);

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  if (error) {
    return <div className="text-danger text-center">{error}</div>;
  }

  const renderCardContent = (key) => {
    const role = userData.role;

    return (
      <>
        {role === "SUPER ADMIN" && (
          <>
            <h5 className="card-title mb-2">
              Amount: ₹{overviewData?.[key]?.amount || 0}
            </h5>
            <p className="card-text mb-1">
              Quantity: {overviewData?.[key]?.quantity || 0}
            </p>
          </>
        )}
        {(role === "PRODUCT MANAGER" || role === "INVENTORY MANAGER") && (
          <p className="card-text mb-1">
            Quantity: {overviewData?.[key]?.quantity || 0}
          </p>
        )}
        {["SUPER ADMIN", "PRODUCT MANAGER", "INVENTORY MANAGER"].includes(
          role
        ) && (
          <p className="card-text mb-0">
            Orders: {overviewData?.[key]?.orders || 0}
          </p>
        )}
        {role === "CUSTOMER CARE" && (
          <p className="card-text mb-0">
            Orders: {overviewData?.[key]?.orders || 0}
          </p>
        )}
      </>
    );
  };

  return (
    <div className="d-flex flex-column vh-100">
      {/* User Info on Top Right */}
      <div className="d-flex justify-content-end align-items-start p-3">
        <div className="text-end">
          <h5 className="text-truncate">{userData.name}</h5>
          <p className="mb-0">Role: {userData.role}</p>
          <p className="mb-0">
            {currentDateTime.toLocaleDateString()}{" "}
            {currentDateTime.toLocaleTimeString()}
          </p>
        </div>
      </div>

      {/* Overview Dashboard */}
      <div className="d-flex justify-content-center align-items-center flex-grow-1 main-content">
        <div className="container">
          <div className="row mb-4">
            <div className="col-12 text-center">
              <h1>Overview Dashboard</h1>
            </div>
          </div>
          <div className="row g-3 justify-content-center">
            {["ELITE", "HEAL", "TOGS", "total"].map((key) => (
              <div key={key} className="col-12 col-md-6 col-lg-4 col-xl-3">
                <div
                  className="card bg-light text-dark mb-3 shadow-lg"
                  style={cardStyle}
                >
                  <div className="card-header bg-secondary text-white">
                    {key.toUpperCase()}
                  </div>
                  <div className="card-body d-flex flex-column justify-content-center">
                    {renderCardContent(key)}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OverView;
